import { NgModule } from '@angular/core';
import { AppCommonModule } from '../common/common.module';
import { HeaderComponent } from './header/header.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { MatButtonModule } from '@angular/material/button';
import { AccountSelectorMenuComponent } from './header/account-selector-menu/account-selector-menu.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    HeaderComponent,
    UserMenuComponent,
    AccountSelectorMenuComponent,
  ],
  imports: [
    AppCommonModule,

    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatInputModule,
  ],
  exports: [
    HeaderComponent,
  ],
})
export class CoreModule {
  constructor(faLibrary: FaIconLibrary) {
    faLibrary.addIcons(
      faCaretDown,
      faBars,
    );
  }
}
