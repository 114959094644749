import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouteLocations } from '../../common/routing/route-locations.enum';
import { Select, Store } from '@ngxs/store';
import { AppState, ToggleNavOpenedAction } from '../../app.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(
    private _store: Store,
  ) {}

  @Select(AppState.isUserLoggedIn)
  public isUserLoggedIn$!: Observable<boolean>;

  public RouteLocations = RouteLocations;

  public toggleNav() {
    this._store.dispatch(new ToggleNavOpenedAction());
  }
}
