<ng-container *ngIf="showMenu">
  <div *ngIf="currentMenuItem"
       [matMenuTriggerFor]="menu"
       (menuOpened)="filterInput?.focus()"
       (menuClosed)="filterInput.value = ''; onFilterTextChanged('')"
       class="ms-md-5 ms-3 cursor-pointer border-dark border-rounded px-2 py-2 flex-row-center user-select-none">
    <app-brand-tag *ngIf="currentMenuItem?.mainBrand" [brand]="currentMenuItem.mainBrand!" [disableNavigation]="true" />

    <div *ngIf="!currentMenuItem?.mainBrand">
      {{ currentMenuItem.account.name }}
    </div>

    <fa-icon icon="caret-down" class="ms-2"></fa-icon>
  </div>

  <mat-menu #menu="matMenu">
    <div (click)="$event.stopPropagation()" class="menu-filter">
      <mat-form-field>
        <mat-label>{{ 'Common.Filter' | translate }}</mat-label>

        <input #filterInput matInput
               (keyup)="onFilterTextChanged(filterInput.value)"
               (search)="onFilterTextChanged(filterInput.value)"
               (blur)="onFilterTextChanged(filterInput.value)">
      </mat-form-field>
    </div>

    <div>
      <button mat-menu-item *ngFor="let item of filteredMenuItems" (click)="selectAccountAsync(item.account)">
        <div class="flex-row-center">
          <app-brand-tag *ngIf="item?.mainBrand" [brand]="item.mainBrand!" [disableNavigation]="true" ></app-brand-tag>

          <div *ngIf="!item?.mainBrand">
            {{ item.account.name }}
          </div>
        </div>
      </button>
    </div>
  </mat-menu>
</ng-container>

<ng-container *ngIf="!showMenu && currentMenuItem && currentMenuItem.mainBrand">
  <div class="ms-md-5 ms-3 border-dark border-rounded px-2 py-2 flex-row-center user-select-none">
    <app-brand-tag [brand]="currentMenuItem.mainBrand" [disableNavigation]="true" ></app-brand-tag>
  </div>
</ng-container>
