import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from './common/services/injection-tokens';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _changeDetector: ChangeDetectorRef,
    @Inject(WINDOW) private _window: Window | null,
  ) {

  }

  public ngOnInit(): void {
    this._router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this._window?.scrollTo?.(<any>{ top: 0, left: 0, behavior: 'instant' });
      });
  }
}
