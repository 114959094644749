import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AccountsService } from '../../../common/services/accounts.service';
import { AccountDTO } from '../../../../../../common/dto/account.dto';
import { BrandMinimalDTO } from '../../../../../../common/dto/brand.dto';
import { debounceTime, Observable, Subject } from 'rxjs';
import { AppState, AppStateModel } from '../../../app.state';
import { Select } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AuthenticationService } from '../../../common/services/authentication.service';

export interface MenuItem {
  account: AccountDTO;

  mainBrand: BrandMinimalDTO | undefined;
}

@UntilDestroy()
@Component({
  selector: 'app-account-selector-menu',
  templateUrl: './account-selector-menu.component.html',
  styleUrls: ['./account-selector-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSelectorMenuComponent implements OnInit {
  private _allMenuItems: MenuItem[] = [];
  private _filterTextSubject = new Subject<string>();

  constructor(
    private _accountsService: AccountsService,
    private _authenticationService: AuthenticationService,
    private _changeDetector: ChangeDetectorRef,
  ) { }

  @Select(AppState)
  public appState$!: Observable<AppStateModel>;

  public showMenu = false;

  public filteredMenuItems: MenuItem[] = [];

  public currentMenuItem?: MenuItem;

  public async ngOnInit() {
    this.appState$
      .pipe(
        map(_ => _.session?.app.accountId),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(async accountId => {
        if (!accountId) {
          this._clearItems();
        } else {
          try {
            const accounts = await this._accountsService.getMyAccountsAsync();

            const brands = await this._accountsService.getAllAvailableBrandsAsync();

            this._allMenuItems = accounts.map(_ => {
              return {
                account: _,
                mainBrand: brands.find(__ => __.accountId === _.id),
              };
            });

            this._allMenuItems.sort((a, b) => (a.mainBrand?.name || '').localeCompare(b.mainBrand?.name || ''));

            this.currentMenuItem = this._allMenuItems.find(_ => _.account.id === accountId);

            this.filteredMenuItems = this._allMenuItems;

            this.showMenu = this._allMenuItems.length > 1;
          } catch {
            this._clearItems();
          }
        }

        this._changeDetector.markForCheck();
      });

    this._filterTextSubject
      .pipe(
        debounceTime(400),
        untilDestroyed(this),
      )
      .subscribe(filterText => {
        const normalizedFilterText = filterText.trim().toLowerCase();

        if (!normalizedFilterText) {
          this.filteredMenuItems = this._allMenuItems;
        } else {
          this.filteredMenuItems = this._allMenuItems.filter(_ =>
            _.account.name.toLowerCase().includes(normalizedFilterText) ||
            _.mainBrand?.name.toLowerCase().includes(normalizedFilterText),
          );
        }

        this._changeDetector.markForCheck();
      });
  }

  public onFilterTextChanged(text: string) {
    this._filterTextSubject.next(text);
  }

  public async selectAccountAsync(account: AccountDTO) {
    await this._authenticationService.switchAccountAsync(account.id);

    this.onFilterTextChanged('');
  }

  private _clearItems() {
    this._allMenuItems = [];
    this.currentMenuItem = undefined;
  }
}
