import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteLocations } from './common/routing/route-locations.enum';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoggedInRouteGuard } from './common/routing/route-guards/logged-in.guard';
import { OnboardingWizardCompleteRouteGuard } from './common/routing/route-guards/onboarding-wizard-complete.guard';
import { PlacementType } from '../../../common/dto/placement.dto';
import { HasAccountRouteGuard } from './common/routing/route-guards/has-account.guard';

const redirectRules: {
  path: string;
  redirectTo: string;
}[] = ([
  {
    path: RouteLocations.Redirect.children.ShopifySettings.absoluteUrl,
    redirectTo: RouteLocations.Dashboard.children.Install.getAbsoluteUrlWithQuery({ type: PlacementType.Shopify }),
  },
  {
    path: RouteLocations.Redirect.children.ShopifyConnect.absoluteUrl,
    redirectTo: RouteLocations.ShopifyIntegration.children.ShopifyConnect.absoluteUrl,
  },
  {
    path: '/login/registration',
    redirectTo: RouteLocations.Login.getAbsoluteUrlWithQuery({
      register: '1',
    }),
  },
]).map(_ => {
  // Keep query params for redirect rules
  if (_.path.startsWith(RouteLocations.Redirect.absoluteUrl + '/')) {
    _.redirectTo = _.redirectTo.slice(1);
  }

  if (_.path.startsWith('/')) {
    _.path = _.path.slice(1);
  }

  return _;
});

const routes: Routes = [
  ...redirectRules,
  {
    path: RouteLocations.Dashboard.relativeUrl,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [
      LoggedInRouteGuard,
      HasAccountRouteGuard,
      OnboardingWizardCompleteRouteGuard,
    ],
  },
  {
    path: RouteLocations.OnboardingWizard.relativeUrl,
    loadChildren: () => import('./onboarding-wizard/onboarding-wizard.module').then((m) => m.OnboardingWizardModule),
    canActivate: [
      LoggedInRouteGuard,
      HasAccountRouteGuard,
    ],
  },
  {
    path: RouteLocations.Settings.relativeUrl,
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: RouteLocations.ShopifyIntegration.relativeUrl,
    loadChildren: () => import('./shopify-integration/shopify-integration.module').then((m) => m.ShopifyIntegrationModule),
  },
  {
    path: RouteLocations.Login.relativeUrl,
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: RouteLocations.Invitation.relativeUrl,
    loadChildren: () => import('./accept-invitation/accept-invitation.module').then((m) => m.AcceptInvitationModule),
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: RouteLocations.NotFound.relativeUrl,
    component: NotFoundComponent,
  },
  {
    path: RouteLocations.Maintenance.relativeUrl,
    loadChildren: () => import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  {
    path: '**',
    redirectTo: RouteLocations.Dashboard.relativeUrl,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
