<div id="header">
  <button *ngIf="isUserLoggedIn$ | async"
          mat-icon-button
          class="d-md-none me-2 p-0"
          style="margin-left: -0.75rem"
          (click)="toggleNav()">
    <fa-icon class="d-inline-block" style="height: 30px" icon="bars" size="2x"></fa-icon>
  </button>

  <div [routerLink]="RouteLocations.Dashboard.absoluteUrl" class="cursor-pointer">
    <app-logo height="30px" color="dark"></app-logo>
  </div>

  <app-account-selector-menu class="d-none d-sm-block"></app-account-selector-menu>

  <div class="flex-grow-1 flex-row-center justify-content-end">
    <app-user-menu></app-user-menu>
  </div>
</div>
