<ng-container *ngIf="session">
  <div [matMenuTriggerFor]="menu" data-test-id="user-menu" class="cursor-pointer text-dark user-select-none">
    {{ session.user.name }}

    <fa-icon icon="caret-down" class="ms-1"></fa-icon>
  </div>
  <mat-menu #menu="matMenu">
    <a mat-menu-item *ngIf="session.user.isEmailValidated"
       [routerLink]="RouteLocations.Settings.absoluteUrl">
      {{ 'Core.UserMenu.Settings' | translate }}
    </a>
    <a mat-menu-item [href]="'mailto:' + supportEmailAddress">
      {{ 'Core.UserMenu.Support' | translate }}
    </a>
    <button mat-menu-item (click)="logoutClickAsync()">
      {{ 'Core.UserMenu.Logout' | translate }}
    </button>
  </mat-menu>
</ng-container>

<ng-container *ngIf="!session">
  <button mat-button color="primary" (click)="goToLoginAsync()" class="bg-transparent">
    <span class="text-dark">{{ 'Core.UserMenu.Login' | translate }}</span>
  </button>
  <button mat-stroked-button color="primary" (click)="goToRegistrationAsync()"
          class="ms-lg-3 bg-transparent border-white">
    <span class="text-dark">{{ 'Core.UserMenu.Register' | translate }}</span>
  </button>
</ng-container>
